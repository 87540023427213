import './full-bleed-video.scss';

class FullBleedVideo {
    constructor (element) {
        this.$videoFullscreen = element;
        this.$videoFullscreenContainer = this.$videoFullscreen.parentElement;
        this.$videoControlPlay = this.$videoFullscreen.nextElementSibling.querySelector('[data-full-bleed-video="play"]');
        this.$videoControlPause = this.$videoFullscreen.nextElementSibling.querySelector('[data-full-bleed-video="pause"]');
        this.$videoControlMute = this.$videoFullscreen.nextElementSibling.querySelector('[data-full-bleed-video="mute"]');
        this.$videoSeekbar = this.$videoFullscreenContainer.querySelector('[data-full-bleed-video="seekbar"]');
        this.$videoSeekbarBar = this.$videoFullscreenContainer.querySelector('[data-full-bleed-video="bar"]');
        this.$videoSeekbarDuration = this.$videoFullscreenContainer.querySelector('[data-full-bleed-video="duration"]');
        this.$videoSeekbarCurrentTime = this.$videoFullscreenContainer.querySelector('[data-full-bleed-video="currentTime"]');
        this.initialize();
    }

    initialize () {
        this.initVideos(this.$videoFullscreen);
    }

    initVideos (video) {
        // play all videos in view
        function playVisibleVideos () {
            const videoContainer = video.parentElement.parentElement;
            videoContainer.querySelectorAll('video').forEach(video => elementIsVisible(video) ? video.play() : video.pause());
        }

        // check if video is visible
        function elementIsVisible (el) {
            const rect = el.getBoundingClientRect();
            return (rect.bottom >= 0 && rect.right >= 0 && rect.top <= (window.innerHeight || document.documentElement.clientHeight) && rect.left <= (window.innerWidth || document.documentElement.clientWidth));
        }

        let playVisibleVideosTimeout;
        clearTimeout(playVisibleVideosTimeout);
        playVisibleVideosTimeout = setTimeout(playVisibleVideos, 50);

        // event scroll
        window.addEventListener('scroll', () => {
            clearTimeout(playVisibleVideosTimeout);
            playVisibleVideosTimeout = setTimeout(playVisibleVideos, 50);
        });

        // event resize/dom loaded
        window.addEventListener('resize', playVisibleVideos);
        window.addEventListener('DOMContentLoaded', playVisibleVideos);

        // handle buttons if video pause
        video.addEventListener('pause', () => {
            this.$videoControlPlay.classList.remove('is--hidden');
            this.$videoControlPause.classList.add('is--hidden');
            video.muted = true;
            video.setAttribute('muted', '');
        });

        // handle buttons if video play
        video.addEventListener('play', () => {
            this.$videoControlPlay.classList.add('is--hidden');
            this.$videoControlPause.classList.remove('is--hidden');
        });

        // play video
        this.$videoControlPlay.addEventListener('click', () => {
            video.play();
            this.$videoControlPlay.classList.add('is--hidden');
            this.$videoControlPause.classList.remove('is--hidden');
        });

        // pause video
        this.$videoControlPause.addEventListener('click', () => {
            video.pause();
            this.$videoControlPlay.classList.remove('is--hidden');
            this.$videoControlPause.classList.add('is--hidden');
        });

        // mute/unmute video
        this.$videoControlMute.addEventListener('click', () => {
            if (video.muted === false) {
                video.muted = true;
                video.setAttribute('muted', '');
                this.$videoControlMute.classList.add('is--muted');
                this.$videoControlMute.classList.remove('has--sound');
            } else {
                video.muted = false;
                video.removeAttribute('muted', '');
                this.$videoControlMute.classList.add('has--sound');
                this.$videoControlMute.classList.remove('is--muted');
            }
        });

        // seekbar handling
        if (this.$videoSeekbarDuration) {
            video.addEventListener('loadedmetadata', () => {
                const duration = video.duration;
                const minutes = Math.floor(duration / 60);
                const seconds = Math.round(duration % 60);
                this.$videoSeekbarDuration.textContent = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
            });
        }

        if (this.$videoSeekbarCurrentTime) {
            video.addEventListener('timeupdate', () => {
                const currentTime = video.currentTime;
                const minutes = Math.floor(currentTime / 60);
                const seconds = Math.round(currentTime % 60);
                this.$videoSeekbarCurrentTime.textContent = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
            });
        }

        if (this.$videoSeekbar) {
            video.addEventListener('timeupdate', () => {
                const percentage = (video.currentTime / video.duration) * 100;
                this.$videoSeekbarBar.style.width = percentage + '%';
            });

            this.$videoSeekbar.addEventListener('mouseover', () => {
                this.$videoSeekbarBar.classList.add('is--active');
            });

            this.$videoSeekbar.addEventListener('mouseout', () => {
                this.$videoSeekbarBar.classList.remove('is--active');
            });

            let sliderCanMove = false;

            this.$videoSeekbar.addEventListener('mousedown', () => {
                sliderCanMove = true;
            });

            window.addEventListener('mousemove', (e) => {
                if (sliderCanMove) {
                    const offset = this.$videoSeekbar.getBoundingClientRect().left;
                    const left = (e.clientX - offset);
                    const totalWidth = this.$videoSeekbar.clientWidth;
                    const percentage = (left / totalWidth);
                    video.currentTime = video.duration * percentage;
                }
            });

            window.addEventListener('mouseup', () => {
                sliderCanMove = false;
            });

            this.$videoSeekbar.addEventListener('click', (e) => {
                const offset = this.$videoSeekbar.getBoundingClientRect().left;
                const left = (e.clientX - offset);
                const totalWidth = this.$videoSeekbar.clientWidth;
                const percentage = (left / totalWidth);
                video.currentTime = video.duration * percentage;
            });
        }
    }
}

export { FullBleedVideo };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $fullBleedVideo = $context.querySelectorAll('[data-full-bleed-video="media"]');
        if ($fullBleedVideo.length > 0) {
            for (let i = 0; i < $fullBleedVideo.length; i++) {
                const fullBleedVideo = $fullBleedVideo[i];
                fullBleedVideo.API = new FullBleedVideo(fullBleedVideo, {
                    loader: window.OPTIONS.loader
                });
            }
        }
    }
});
